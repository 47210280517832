import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Modal,
  Button,
  FormGroup
} from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
// import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import profile from "assets/images/login_v1.svg"

import {
  // LoginByCode,
  LoginByUsernameAndPassword,
  LoginNhanvienByCodeAndMaDoiTac,
  LoginByUsernameAndPasswordRequireStaff
} from "../../services/auth"

import { STORAGE_KEY, STORAGE_KEY_TIMEOUT } from "configs/app.const"
import Cookies from "universal-cookie"

//i18n
import { withTranslation } from "react-i18next"
// import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown"

//i18n
import i18n from "i18n"
import languages from "common/languages"

import { get, map } from "lodash"

const Login = props => {
  //meta title
  document.title = props.t("Log In")
  const history = useNavigate()
  const cookies = new Cookies()
  const [error, setError] = useState("")
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accessToken, setAccessToken] = useState("")
  const [refreshToken, setRefreshToken] = useState("")
  const [username, setUsername] = useState("")
  const [fullname, setFullname] = useState("")

  const [selectedLang, setSelectedLang] = useState("")

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || '',
      // password: "123456" || '',
      // code: ''
      username: "",
      password: "",
      isRemember: true
    },
    validationSchema: Yup.object({
      username: Yup.string().required(props.t("Please Enter Your Username")),
      password: Yup.string().required(props.t("Please Enter Your Password"))
    }),
    onSubmit: async values => {
      await LoginByUsernameAndPasswordRequireStaff(values)
        .then(response => {
          setIsSuccess(true)
          setIsError(false)
          setAccessToken(response.data.token)
          setUsername(values.username)
          setFullname(response.data.fullname)
          if (values.isRemember) {
            setRefreshToken(response.data.refreshToken)
          } else {
            setRefreshToken(null)
          }
        })
        .catch(err => {
          setIsSuccess(false)
          setIsError(true)
          console.log(err)
          setError(props.t("Invalid credentials"))
        })
    }
  })

  const validation_login_nhanvien = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: ""
    },
    validationSchema: Yup.object({
      code: Yup.string().required(props.t("Please Enter Your Staff Code"))
    }),
    onSubmit: async values => {
      values.ma_doi_tac = validation.values.username
      await LoginNhanvienByCodeAndMaDoiTac(values)
        .then(res => {
          cookies.set(STORAGE_KEY.code, values.code, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          cookies.set(STORAGE_KEY.auth, accessToken, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          cookies.set(STORAGE_KEY.madoitac, username, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          cookies.set(STORAGE_KEY.ho_va_ten, fullname, { path: "/", maxAge: STORAGE_KEY_TIMEOUT.user })
          if (refreshToken) {
            cookies.set(STORAGE_KEY.refresh_token, refreshToken, {
              path: "/",
              maxAge: STORAGE_KEY_TIMEOUT.refresh_token
            })
          } else {
            cookies.remove(STORAGE_KEY.refresh_token)
          }
          if (values.ma_doi_tac === "mrhaidanang" || values.ma_doi_tac === "anphucsg") {
            history("/evisa")
          } else {
            history("/submit-profile")
          }
        })
        .catch(err => {
          setError(err?.response?.data?.msg)
          setIsError(true)
        })
    }
  })

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    setSelectedLang(currentLanguage)
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Modal isOpen={isSuccess} backdrop={"static"} id="staticBackdrop">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                validation_login_nhanvien.handleSubmit()
                return false
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {props.t("Enter staff code")}
                </h5>
              </div>
              <div className="modal-body">
                {isError === true ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Label className="form-label">{props.t("Staff Code")}</Label>
                  <Input
                    name="code"
                    value={validation_login_nhanvien.values.code || ""}
                    type="text"
                    placeholder={props.t("Staff Code")}
                    onChange={validation_login_nhanvien.handleChange}
                    onBlur={validation_login_nhanvien.handleBlur}
                    invalid={
                      validation_login_nhanvien.touched.code && validation_login_nhanvien.errors.code ? true : false
                    }
                  />
                  {validation_login_nhanvien.touched.code && validation_login_nhanvien.errors.code ? (
                    <FormFeedback type="invalid">{validation_login_nhanvien.errors.code}</FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    history("/submit-profile")
                  }}
                >
                  Bỏ qua
                </button> */}
                <button type="submit" className="btn btn-primary">
                  Xác nhận
                </button>
              </div>
            </Form>
          </Modal>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft position-relative">
                  {/* <div className="login-lang">
                    <LanguageDropdown label={props.t("Languages")} />
                  </div> */}
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{props.t("Welcome Back !")}</h5>
                        <p>{props.t("Visa Vietnam System")}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {isError === true ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          value={validation.values.username || ""}
                          type="text"
                          placeholder={props.t("Username")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.username && validation.errors.username ? true : false}
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={props.t("Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.password && validation.errors.password ? true : false}
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <FormGroup check>
                        <Input
                          name="isRemember"
                          type="checkbox"
                          id="isRemember"
                          defaultChecked={true}
                          value={validation.values.isRemember}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        <Label check for="isRemember">
                          Remember me
                        </Label>
                      </FormGroup>

                      <div className="mt-3 d-grid">
                        <button className="btn btn-primary btn-block" type="submit">
                          {props.t("Log In")}
                        </button>
                      </div>
                    </Form>
                  </div>
                  <div className="d-flex justify-content-center p-2">
                    {map(Object.keys(languages), key => (
                      <div className="me-2" key={key}>
                        <Button
                          key={key}
                          color="none"
                          onClick={() => {
                            changeLanguageAction(key)
                          }}
                        >
                          {" "}
                          <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-1" height="12" />
                          <span className="align-middle">{get(languages, `${key}.label`)}</span>
                        </Button>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object
}

Login.propTypes = {
  t: PropTypes.any
}

export default withRouter(withTranslation()(Login))
